// Importations
import {
  Component,
  HostListener,
  ElementRef,
  AfterViewInit,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import {
  Router,
  NavigationEnd,
  Event as NavigationEvent,
} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Subscription, filter } from 'rxjs';
import { DrawerService } from '../../services/drawer.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../../services/user.service';
import * as CryptoJS from 'crypto-js';
import Intercom from '@intercom/messenger-js-sdk';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  // Variables membres
  private apiUrl = environment.apiUrl;
  private currentScrollTop = 0;
  private headerIsAlwaysSticky = false;
  private preventReveal = false;
  private headerBounds: any;
  private header!: HTMLElement;
  private sidebarVisibilitySubscription!: Subscription;
  private userSubscription!: Subscription;
  private authSubscription!: Subscription;
  private translationSubscription!: Subscription;
  mobileMenuVisible = false;
  private userData: any;
  activeUrl!: string;
  userHasSubscription = false;
  sidebarVisible: boolean = false;
  items!: MenuItem[];
  profileItems!: MenuItem[];
  headerTranslations: any;
  activeUser: boolean = false;

  // Constructeur
  constructor(
    private el: ElementRef,
    private router: Router,
    public authService: AuthService,
    private userService: UserService,
    private drawerService: DrawerService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {}

  // Cycle de vie Angular
  async ngOnInit() {
    this.loadTranslations();
    this.subscribeToSidebarVisibility();
    this.subscribeToRouterEvents();
    this.subscribeToUserData();
  }

  ngAfterViewInit() {
    this.header = this.el.nativeElement.querySelector('header');
    this.createObserver();
  }

  ngOnDestroy() {
    this.sidebarVisibilitySubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.translationSubscription.unsubscribe();
  }

  private loadTranslations() {
    this.translationSubscription = this.translate
      .get('header')
      .subscribe((translations) => {
        this.headerTranslations = translations;
        this.initializeMenu();
      });
  }

  private subscribeToSidebarVisibility() {
    this.sidebarVisibilitySubscription = this.drawerService
      .getSidebarVisible()
      .subscribe((visible) => {
        this.sidebarVisible = visible;
      });
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter(
          (event: NavigationEvent): event is NavigationEnd =>
            event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        this.activeUrl = event.urlAfterRedirects;
        this.updateMenu();
      });
  }

  private subscribeToUserData() {
    this.userSubscription = this.userService
      .getUserData()
      .subscribe((userData) => {
        this.userData = userData;

        if (this.userData) {
          this.activeUser = this.userData.active;
          this.updateProfileMenu();
          this.addBugsMenuItem();
          this.filterItemsForAdmin(this.userData.roles);
          this.getIntercomBox();
        }
      });
  }

  private addBugsMenuItem() {
    if (
      this.userData?.subscriptions.length > 0 &&
      this.profileItems &&
      this.profileItems[0] &&
      this.profileItems[0].items
    ) {
      this.userHasSubscription = true;
      this.profileItems[0].items?.splice(-1, 0, {
        label: this.translate.instant('dashboard_account.bugs.title'),
        url: '/dashboard/account/bugs',
        target: '_self',
        icon: 'fa-regular fa-bug',
        ariaLabel: this.translate.instant('dashboard_account.bugs.title'),
      });
      this.cd.detectChanges();
    }
  }

  private initializeMenu() {
    this.setMenu();
    this.authSubscription = this.authService.isLoggedIn$.subscribe(
      (isLoggedIn: boolean) => {
        this.updateProfileMenuBasedOnLoginStatus(isLoggedIn);
      },
    );
  }

  private setMenu() {
    this.items = [
      {
        label: this.headerTranslations.items.home,
        url: '/',
        target: '',
      },
      {
        label: this.headerTranslations.items.demos,
        url: '/demos',
        target: '',
        ariaLabel: this.headerTranslations.items.demos,
      },
      {
        label: this.headerTranslations.items.pricing,
        url: '/pricing',
        target: '',
        ariaLabel: this.headerTranslations.items.pricing,
      },
      {
        label: this.headerTranslations.items.affiliation,
        url: 'affiliation',
        target: '',
        disabled: false,
        ariaLabel: this.headerTranslations.items.affiliation,
      },
      {
        label: this.headerTranslations.items.partners,
        url: 'partners',
        target: '',
        disabled: false,
        ariaLabel: this.headerTranslations.items.partners,
      },
      {
        label: this.headerTranslations.items.informations,
        url: '',
        target: '',
        ariaLabel: this.headerTranslations.items.informations,
        items: [
          {
            label: this.headerTranslations.items.faq,
            url: '/faq',
            target: '',
            disabled: false,
            icon: 'fa-regular fa-question',
            ariaLabel: this.headerTranslations.items.faq,
          },
          {
            label: this.headerTranslations.items.tutorials,
            url: 'https://help.phantom-theme.fr/fr/',
            target: '_blank',
            disabled: false,
            icon: 'fa-regular fa-book',
            ariaLabel: this.headerTranslations.items.tutorials,
          },
          {
            label: this.headerTranslations.items.blog,
            url: 'https://blog.phantom-theme.fr/',
            target: '_blank',
            disabled: false,
            icon: 'fa-regular fa-square-rss',
            ariaLabel: this.headerTranslations.items.blog,
          },
          {
            label: this.headerTranslations.items.contact,
            url: '/contact',
            target: '',
            disabled: false,
            icon: 'fa-regular fa-envelope',
            ariaLabel: this.headerTranslations.items.contact,
          },
        ],
      },
    ];
    this.filterItemsForAdmin();
  }

  private updateProfileMenuBasedOnLoginStatus(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.profileItems = [
        {
          icon: 'fa-regular fa-user',
          id: 'account-menu',
          url: '/dashboard/account/licenses',
          label: this.headerTranslations.items.account,
          ariaLabel: this.headerTranslations.items.account,
          target: '_self',
          items: [
            {
              label: this.headerTranslations.items.licenses,
              url: '/dashboard/account/licenses',
              target: '_self',
              icon: 'fa-brands fa-product-hunt',
              ariaLabel: this.headerTranslations.items.licenses,
            },
            {
              label: this.headerTranslations.items.account,
              url: '/dashboard/account/profile',
              target: '_self',
              icon: 'fa-regular fa-id-card',
              ariaLabel: this.headerTranslations.items.account,
            },
            {
              label: this.headerTranslations.items.logout,
              command: () => this.logout(),
              url: '/login',
              target: '_self',
              icon: 'fa-regular fa-right-from-bracket',
              ariaLabel: this.headerTranslations.items.logout,
            },
          ],
        },
      ];
    } else {
      this.profileItems = [
        {
          label: this.headerTranslations.items.login,
          url: '/login',
          ariaLabel: this.headerTranslations.items.login,
          target: '_self',
        },
      ];
    }
  }

  private updateProfileMenu() {
    this.updateProfileMenuBasedOnLoginStatus(
      this.authService.getLoggedInValue(),
    );
  }

  // Mise à jour du menu
  private updateMenu(): void {
    this.items = this.items.map((item) => this.updateMenuItem(item, false));
    this.profileItems = this.profileItems.map((item) =>
      this.updateMenuItem(item, false),
    );
  }

  private updateMenuItem(item: MenuItem, isSub: boolean): MenuItem {
    const updatedItem = {
      ...item,
      styleClass:
        this.activeUrl === item.url
          ? `relative active-menu-item ${isSub ? 'is-sub-item' : ''}`
          : '',
    };

    if (item.items) {
      updatedItem.items = item.items.map((subItem) =>
        this.updateMenuItem(subItem, true),
      );
    }

    return updatedItem;
  }

  // Méthodes utilitaires
  private logout() {
    this.authService.clearAuthToken();
    this.userService.clearUserData();
    this.router.navigate(['/login']);
  }

  private filterItemsForAdmin(roles: Array<string> = []) {
    const isAdmin =
      roles.includes('ROLE_ADMIN') || roles.includes('ROLE_OWNER');
    if (isAdmin && !this.items.find((item) => item.label === 'Admin')) {
      this.items.push({
        label: 'Admin',
        url: `${this.apiUrl}/admin/login`,
        target: '_blank',
        icon: 'fa-regular fa-gear',
      });
    }
  }

  private encrypt(text: string): string {
    return CryptoJS.HmacSHA256(
      text,
      environment.intercomAppSecretKey,
    ).toString();
  }

  private getIntercomBox() {
    const userHash = this.encrypt(this.userData?.email || '');

    try {
      Intercom({
        api_base: 'https://api-iam.intercom.io',
        app_id: 'f51mooij',
        name: this.userData
          ? `${this.userData.firstname} ${this.userData.lastname}`
          : '',
        email: this.userData?.email || null,
        created_at: this.userData?.createdAt || '1312182000',
        user_hash: userHash,
        stripe_id: this.userData?.stripeCustomerId || null,
      });
    } catch (error) {
      console.error('Intercom error', error);
    }

    if (this.router.url.includes('#open-chatbot')) {
      //Intercom('show');
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  // Gestion des événements
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.handleScroll(scrollTop);
  }

  private createObserver() {
    const observer = new IntersectionObserver((entries) => {
      this.headerBounds = entries[0].intersectionRect;
      observer.disconnect();
    });

    observer.observe(this.header);
  }

  closeHeaderBanner(){
    this.header.classList.add('header-banner-closed');
  }

  private handleScroll(scrollTop: number) {
    if (
      scrollTop > this.currentScrollTop &&
      scrollTop > this.headerBounds.bottom
    ) {
      this.header.classList.add('scrolled-past-header');
      if (!this.preventReveal) requestAnimationFrame(() => this.hide());
    } else if (
      scrollTop < this.currentScrollTop &&
      scrollTop > this.headerBounds.bottom
    ) {
      this.header.classList.add('scrolled-past-header');
      if (!this.preventReveal) {
        requestAnimationFrame(() => this.reveal());
      } else {
        setTimeout(() => {
          this.preventReveal = false;
        }, 66);
        requestAnimationFrame(() => this.hide());
      }
    } else if (scrollTop <= this.headerBounds.top) {
      this.header.classList.remove('scrolled-past-header');
      requestAnimationFrame(() => this.reset());
    }
    this.currentScrollTop = scrollTop;
  }

  private hide() {
    if (!this.headerIsAlwaysSticky) {
      this.header.classList.add('header-hidden', 'header-sticky');
    }
  }

  private reveal() {
    if (!this.headerIsAlwaysSticky) {
      this.header.classList.add('header-sticky');
      this.header.classList.remove('header-hidden');
    }
  }

  private reset() {
    if (!this.headerIsAlwaysSticky) {
      this.header.classList.remove('header-hidden', 'header-sticky');
    }
  }

  toggleSidebar() {
    this.drawerService.toggleSidebar();
  }

  redirectPricing() {
    this.router.navigate(['/pricing']);
  }
}
