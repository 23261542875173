import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swiper from 'swiper';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-youtube-last-videos-carousel',
  templateUrl: './youtube-last-videos-carousel.component.html',
  styleUrls: ['./youtube-last-videos-carousel.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition('* => void', [
        animate('0.5s ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class YoutubeLastVideosCarouselComponent implements OnInit {
  @Input() width: number = 280;
  private YoutubeApiKey = environment.youtubeApiKey;
  private channelId = environment.youtubeChannelId;
  private apiUrl = 'https://www.googleapis.com/youtube/v3';
  videos: any[] = [];
  private swiperInstance?: Swiper;
  @ViewChild('swiperContainer') swiperContainer!: ElementRef;
  @ViewChild('noContentContainer') noContentContainer!: ElementRef;
  height: string = 'auto';

  constructor(private http: HttpClient, private renderer: Renderer2, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.getLatestVideos().subscribe(
      (response) => {
        this.videos = response.items
          .filter((item: any) => item.snippet.description !== '')
          .slice(0, 5);
        this.videos.forEach((video) => {
          video.snippet.title = this.sanitizeTitle(video.snippet.title);
        });
        this.waitSlide();
      },
      (error) => {
        if (error.status == 403) {
          this.videos = [];
        }
      }
    );
  }

  sanitizeTitle(title: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }

  getLatestVideos(): Observable<any> {
    const url = `${this.apiUrl}/search?key=${this.YoutubeApiKey}&channelId=${this.channelId}&part=snippet,id&order=date&maxResults=32&type=video`;

    return this.http.get(url);
  }

  getVideoUrl(videoId: string): string {
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  private waitSlide(): void {
    const x = setInterval(() => {
      if (
        this.swiperContainer.nativeElement &&
        this.swiperContainer.nativeElement.querySelectorAll('.swiper-slide')
          .length > 0
      ) {
        clearInterval(x);
        this.initialiserSwiper();
      }
    }, 100);
  }

  private initialiserSwiper(): void {
    if (
      this.swiperContainer &&
      (!this.swiperInstance || this.swiperInstance.destroyed)
    ) {
      var self = this;
      this.swiperInstance = new Swiper(this.swiperContainer.nativeElement, {
        rewind: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        width: this.width,
        spaceBetween: 20,
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          resize() {
            self.swiperInstance?.update();
          },
        },
      });
    }
  }

  removeSlide(index: number): void {
    this.videos[index].removing = true;

    setTimeout(() => {
      this.videos.splice(index, 1);
      this.swiperInstance?.removeSlide(index);

      if (this.videos.length === 0) {
        setTimeout(() => {
          this.animateContainerHeight();
        }, 200);
      }
    }, 500);
  }

  animateContainerHeight() {
    const height = this.noContentContainer.nativeElement.offsetHeight;

    this.renderer.setStyle(
      this.noContentContainer.nativeElement,
      'height',
      height + 'px'
    );

    this.renderer.setStyle(
      this.noContentContainer.nativeElement,
      'transition',
      'height 2s, opacity 1s'
    );

    setTimeout(() => {
      this.renderer.setStyle(
        this.noContentContainer.nativeElement,
        'opacity',
        '0'
      );

      setTimeout(() => {
        this.renderer.setStyle(
          this.noContentContainer.nativeElement,
          'height',
          '0'
        );
      }, 1000);
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.swiperInstance && !this.swiperInstance.destroyed) {
      this.swiperInstance.destroy(true, true);
      this.swiperInstance = undefined;
    }
  }
}
